import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import {
  ButtonSize,
  Button as TpaButton,
  ButtonProps as TpaButtonProps,
  ButtonPriority as TpaButtonPriority,
} from 'wix-ui-tpa';
import { classes, st } from './Button.st.css';
import { ButtonVariant } from './constants';

export interface ButtonProps extends Omit<TpaButtonProps, 'priority'> {
  variant?: ButtonVariant;
}

const mapVariantToPriority = {
  [ButtonVariant.FILLED]: TpaButtonPriority.primary,
  [ButtonVariant.OUTLINED]: TpaButtonPriority.secondary,
};

export const Button: React.FC<ButtonProps> = ({
  variant = ButtonVariant.FILLED,
  ...restProps
}) => {
  const { isMobile } = useEnvironment();

  return (
    <TpaButton
      {...restProps}
      className={st(classes.root, { isMobile, variant })}
      size={ButtonSize.medium}
      upgrade
      data-variant={variant}
      priority={mapVariantToPriority[variant]}
    />
  );
};
